/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/**
 * Theme for Axova
 */
@import "theme/axova-fireworks";
@import "theme/axova-fonts";
@import "theme/axova-ionic-overrides";
@import "theme/axova-variables";

h1 {
  font-size: 30px;
  font-family: 'Gotham Narrow Bold', sans-serif;
}

h1, h2, h3 {
  font-weight: 600;
}

html {
  transition: opacity 0.3s ease;

  &.opacity0 {
    opacity: 0;
  }
}

.no-margin-bottom {
  margin-bottom: 0;
}

ion-footer {
  box-shadow: rgba(0, 0, 0, 0.1) 10px 10px 10px 10px;
}

.ion-card-header--with-icon {
  display: flex;
  justify-content: space-between;
  align-items: center;

  ion-icon {
    color: var(--ion-color-primary);
    font-size: 24px;
  }
}

ion-item.checkmark-circle-outline {
  --detail-icon-color: var(--ion-color-primary);
}

ion-item.close-circle-outline {
  --detail-icon-color: var(--ion-color-danger);
}

ion-item.ellipse-outline {
  --detail-icon-color: var(--ion-color-medium);
}

.font-size-default {
  font-size: 16px !important;
}

ion-card-title {
  font-size: 20px;
}

hr {
  background-color: var(--ion-color-medium);
}

.attachment-gallery {
  .attachment {
    padding: 0;
    margin: 0 0 20px 0;

    img {
      width: 100%;
      height: auto;
    }
  }
}

