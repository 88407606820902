/**
 * Axova specific Ionic overrides.
 */
:root {
  --ion-font-family: 'Gotham Narrow';
}

ion-toolbar {
  --ion-font-family: 'Gotham Narrow Bold';
}

ion-item {
  --detail-icon-color: var(--ion-color-primary);
  --detail-icon-opacity: 1;
}
